<template>
  <div :style="{ height: contentStyleObj1 }" class="main">
    <div style="margin-bottom: 15px;">账期选择</div>
    <qzf-period v-model:period="period" style="width:102px" :hidden="true" @change="updatePeriod"></qzf-period>
    <!-- <el-select size="small" v-model="period" style="width:90px" @change="updatePeriod">
      <el-option v-for="item in datas" :key="item.period" :label="item.period" :value="item.period"></el-option>
    </el-select> -->
    <div style="margin-top: 15px;cursor: pointer;color: var(--themeColor,#17a2b8);" @click="scrollToTop">
      <el-icon><ArrowUpBold /></el-icon>
    </div>
   
    <div class="table_height" :style="{ height: contentStyleObj }" id="flexImd">
      <!-- <ul style="border-left: 4px solid white">
        <li v-for="(item,index) in datas" :key="index" @click="changeLi(index,item)" class="navbar-item" :style="item.show?{'background-color':'#d2e1fd','cursor':'pointer'}:{'background-color':'#f1f1f1','cursor':'not-allowed','color':'#909399'}"  v-bind:class="{clicked: index === currNavbar}">  
          <span>{{ item.period }}</span>
          <span style="color:#008052;margin-left: 5px;" v-if="item.status && item.status == 4">已结账</span>
          <span style="margin-left: 5px;color: #909399;" v-if="item.status && item.status != 4">未结账</span>
        </li>
      </ul> -->

      <el-timeline style="padding-left: 80px;width: 25px;" hide-timestamp>
        <el-timeline-item
          @click="changeLi(index, item)"
          class="navbar-item"
          v-for="(item, index) in datas"
          :key="index"
          :type="item.type"
          :icon="item.icon"
          :size="item.size"
          :color="item.color"
          placement="top"
        >
          <!-- <span style="color:#008052;margin-left: 5px;" v-if="item.status && item.status == 4">已结账</span>
          <span style="margin-left: 5px;color: #909399;" v-if="item.status && item.status != 4">未结账</span> -->

          <div
            style="position: relative;left: -83px;top: -10px;font-size: 12px;"
            :style="
              item.show
                ? { cursor: 'pointer' }
                : { cursor: 'not-allowed', color: '#909399 !important' }
            "
            v-bind:class="{ clicked: index === currNavbar }"
          >
            {{ item.period }}
          </div>
          <!-- <div style="position: relative;top: -27px;right: 10px;" :style="item.show?{'cursor':'pointer'}:{'cursor':'not-allowed','color':'#909399 !important' }" v-bind:class="{clicked: index === currNavbar}"> 
            <span style="color:#008052;margin-left: 5px;" v-if="item.status && item.status == 4">已结账</span>
            <span style="margin-left: 5px;color: #909399;" v-if="item.status && item.status != 4">未结账</span>
          </div> -->
        </el-timeline-item>
      </el-timeline>
    </div>
    <div style="margin-top: 15px;cursor: pointer;color: var(--themeColor,#17a2b8);" @click="scrollToBottom">
      <el-icon><ArrowDownBold /></el-icon>
    </div>
  </div>
</template>

<script>
import { init } from "echarts";
import { getPeriods } from "@/api/carryover.js";

export default {
  name: "slidePeriod",
  props:{
    contentStyleObj2: { 
      type:  Number,
      default: 123
    },
  },
  data() {
    return {
      contentStyleObj: {}, //高度变化
      datas: [
        {
          value: "2022年09期",
          label: "2022年09期",
        },
        {
          value: "33",
          label: "33",
        },
        {
          value: "44",
          label: "44",
        },
        {
          value: "55",
          label: "55",
        },
        {
          value: "66",
          label: "66",
        },
        {
          value: "77",
          label: "77",
        },
        {
          value: "88",
          label: "88",
        },
        {
          value: "99",
          label: "99",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
        {
          value: "13",
          label: "13",
        },
        {
          value: "14",
          label: "14",
        },
        {
          value: "15",
          label: "15",
        },
        {
          value: "16",
          label: "16",
        },
        {
          value: "17",
          label: "17",
        },
        {
          value: "18",
          label: "18",
        },
        {
          value: "19",
          label: "19",
        },
      ],
      currNavbar: "",
      currentIndex: 0,
      item: [],
      contentStyleObj1: {},
      period:this.$store.getters["user/comInfo"].period
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(463);
    this.contentStyleObj1 = this.$getHeight(this.contentStyleObj2);
    this.init();
  },
  activated(){
    let period = this.$store.getters["user/comInfo"].period;
    var currentIndex = this.datas.findIndex((item) => {
      if (item.period == period) {
        this.item = item;
      }
      return item.period == period;
    });
    this.changeLi(currentIndex, this.item);
  },
  mounted() {},

  methods: {
    init() {
      getPeriods({}).then((res) => {
        this.datas = res.data.data.periods;
        this.datas.map((v) => {
          if (v.status == 4) {
            v.icon = "CircleCheckFilled";
            v.type = "success";
            v.size = "large";
            v.color = "#67c23a";
            v.timestamp = "已结账";
          } else if (v.status == 5 || v.status == 2 || v.status == 1) {
            v.icon = "WarningFilled";
            v.type = "warning";
            v.size = "large";
            v.color = "#e6a23c";
            v.timestamp = "未结账";
          } else {
            v.timestamp = "待做账";
          }
        });
        let period = this.$store.getters["user/comInfo"].period;
        var currentIndex = this.datas.findIndex((item) => {
          if (item.period == period) {
            this.item = item;
          }
          return item.period == period;
        });
        this.changeLi(currentIndex, this.item);
      });
    },
    getList(param) {
      getPeriods({}).then((res) => {
        this.datas = res.data.data.periods;
        let itemss = [];
        this.datas.map((item) => {
          if (item.status == 4) {
            item.icon = "CircleCheckFilled";
            item.type = "success";
            item.size = "large";
            item.color = "#67c23a";
            item.timestamp = "已结账";
          } else if (item.status == 5 || item.status == 2 || item.status == 1) {
            item.icon = "WarningFilled";
            item.type = "warning";
            item.size = "large";
            item.color = "#e6a23c";
            item.timestamp = "未结账";
          } else {
            item.timestamp = "待做账";
          }
          if (item.period == param.period) {
            itemss = item;
          }
        });
        this.$emit("success", itemss);
      });
    },
    changeLi(index, item) {
      //console.log(index,item);
      if (item && !item.show) {
        return;
      }
      this.period = item.period
      this.currNavbar = index;
      this.$nextTick(() => {
        const item = this.$el.querySelectorAll(".navbar-item")[index];
        const container = document.querySelector("#flexImd");
        const scrollTop =
          item.offsetTop - (container.offsetHeight - item.offsetHeight) / 2;
        container.scrollTo({
          top: scrollTop,
          behavior: "smooth",
        });
      });
      if (item) {
        //console.log(item, "xx");
        this.$emit("success", item);
      }
    },
    updatePeriod(){
      var currentIndex = this.datas.findIndex((item) => {
        if (item.period == this.period) {
          this.item = item;
        }
        return item.period == this.period;
      });
      this.$emit("success", this.item);
      this.changeLi(currentIndex, this.item);
    },
    scrollToTop(){
      this.$nextTick(() => {
        const container = document.querySelector("#flexImd");
        container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    },
    scrollToBottom(){
      this.$nextTick(() => {
        const container = document.querySelector("#flexImd");
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth",
        });
      });
    }
    // filerPeriod(type){
    //   return type.slice(0,4) +'年'+ type.slice(4) + '期'
    // }
  },
};
</script>

<style lang="scss" scoped>
// li {
//   line-height: 60px;
//   text-align: center;
//   margin-bottom: 3px;
//   // color: #909399;
//   // background: skyblue;
// }
li:nth-child(odd) {
  // background-color: pink;
}

.table_height {
  overflow-y: overlay;
  overflow-x: hidden;
  margin-top: 10px;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 0px;
}
/*滚动条里面小方块*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(100, 100, 100, 0.2);
}
.clicked {
  color: var(--themeColor, #17a2b8) !important;
  font-weight: 600;
}
:deep(.el-timeline-item){
  padding-bottom: 10px;
}
:deep(.el-timeline-item__wrapper){
  padding-left: 15px;
}
.main{
  border: 1px solid #efefef;
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
}
</style>
